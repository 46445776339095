//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Add from "./adddialog.vue";
import { _getlist ,_settemplateDele} from "@/api/maintenance";
import { _getcode } from "@/api/organizational";
export default {
  components: {
    Add,
  },
  data() {
    return {
       pageSize: 10, //没页几条
      current: 1, //当前页数
      total: 0, //总条数
      formInline: {
        name: "",
        status: "",
      },
      options: [ ],
      value: "",
      input: "",
      tableData: [],
    };
  },
  created() {
    this.getList({
      current: this.current,
      page: this.pageSize,
      ...this.formInline
    });
    this.getcode()
  },
  methods: {
     tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    //查询事件
    query() {
      this.getList(this.formInline)
    },
     //模板状态状态的下拉数据
    getcode(){
       let from = {
        para: "mbzt",
      };
      _getcode(from).then((res)=>{
         this.options = res.data;
      })
    },
    // 新增事件
    add() {
      this.$router.push("/main/maintenance/mode/equipment")
    },
    // 列表数据
    getList(from) {
      _getlist(from).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
        res.data.data.forEach((item) => {
          item.contentTypename = item.contentType == 1 ? "工单" : "设备";
        });
      });
    },
    handleClick(row){
      if(row.contentType == '1'){
         this.$router.push({
          path: "/main/maintenance/mode/ordertempl",
          query: { id: row.id,type:"1"},
        });
      }else{
         this.$router.push({
          path: "/main/maintenance/mode/equipment",
          query: { id: row.id,type:"2"},
        });
      }
    },
    delet(row){
       this.$confirm('是否确认删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        _settemplateDele({id:row.id}).then((res)=>{
          if(res.code == '1'){
            this.$message(res.msg)
            this.getList({});
          }
       })
        })
     
    },
    handleSizeChange(val){
       this.current = 1;
      this.pageSize = val;
      let from = {
        current: this.current,
        page: this.pageSize,
      };
      this.getList(from)
    },
    handleCurrentChange(val){
       this.current = val;
      let from = {
        current: this.current,
        page: this.pageSize,
      };
      this.getList(from)
    },
  },
};
