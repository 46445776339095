// 维保的接口
import { request, requestFd } from '../core/request'

// 获取数据列表
export function _getlist(params) {
    console.log('数据列表')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/list',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 新增维保设备模板接口
export function _setequtment(params) {
    console.log('新增维保设备模板')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/equtment_init',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 删除
export function _settemplateDele(params) {
    console.log('删除维工单备模板')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/templateDele',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

// 修改工单
export function _settemplateEdit(params) {
    console.log('修改模板')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/templateEdit',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 修改设备
export function _settemplateEquipmentEdit(params) {
    console.log('修改模板')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/templateEquipmentEdit',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 新增维保工单模板接口
export function _setedepts(params) {
    console.log('新增维工单备模板')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/dept_init',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 详情数据
export function _settemplateEditInfo(params) {
    console.log('详情数据')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/templateEditInfo',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 禁用
export function _setplanDisable(params) {
    console.log('禁用')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/planDisable',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

// 启用
export function _setplanEndisable(params) {
    console.log('启用')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/planEndisable',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 修改计划
export function _setplanEdit(params) {
    console.log('修改计划')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/planEdit',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

// 维保计划新增获取所有部门
export function _setalldept(params) {
  console.log('维保计划新增获取所有部门')
  return request({
      url:'/ananops/api/v1/pri/maintenance/plan/alldept',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}



// 设备模板下拉接口
export function _setequmentlist(params) {
    console.log('设备模板下拉接口')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/equmentlist',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 维保计划的新增   时间段的接口获取
export function _getdatelist(params) {
    console.log('时间段的接口获取')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/datelist',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
//维保计划的确定事件
export function _getmake(params) {
    console.log('时间段的接口获取')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/make',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

//维保计划的列表页
export function _planList(params) {
    console.log('维保计划的列表页')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/planList',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}


//工单末班列表下拉框
export function _getdepttemplate(params) {
    console.log('工单末班列表下拉框')
    return request({
        url:'/ananops/api/v1/pri/maintenance/template/depttemplate',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

//负责人的下拉列表
export function _getRespLeader(params) {
    console.log('负责人的下拉列表')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/getRespLeader',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
//详情数据
export function _getplanInfo(params) {
    console.log('详情数据')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/planInfo',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

// 维保工单状态标签
export function _getorderTypeLabel(params) {
    console.log('维保工单状态标签')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/orderTypeLabel',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}


//维保工单列表
export function _getorderList(params) {
    console.log('维保工单列表')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/orderList',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

//维保计划确认
export function _getconfim(params) {
    console.log('维保计划确认')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/confim',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}


//生成工单
export function _getcreateOrder(params) {
    console.log('生成工单')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/createOrder',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

//工单详情
export function _getorderInfo(params) {
    console.log('工单详情')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/orderInfo',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

//指派工程师
export function _getengineerDispatch(params) {
    console.log('指派工程师')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/engineerDispatch',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

//工程师实施
export function _getorderImplement(params) {
    console.log('工程师实施')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/orderImplement',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
//验收的接口
export function _getcheck(params) {
    console.log('完成的接口')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/check',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
//完成的接口
export function _getorderOver(params) {
    console.log('完成的接口')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/orderOver',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 

//多选
export function _getorderImplements(params) {
    console.log('多选')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/orderImplements',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
//维保设备申请列表
export function _getequipmentOrderList(params) {
    console.log('维保设备申请列表')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentOrderList',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 获取游离设备的服务商
export function _getfreeEquipmentSP(params) {
    console.log('维保设备申请列表')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/freeEquipmentSP',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 获取服务商对应需求方的部门
export function _getdemandDept(params) {
    console.log('维保设备申请列表')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/demandDept',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

// 游离设备提交
export function _freeOrderImplement(params) {
    console.log('游离设备提交')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/freeOrderImplement',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 部门接口
export function _selectUserBindDeptList(params) {
    console.log('游离设备提交')
    return request({
        url:'/ananops/api/v1/pri/report/selectUserBindDeptList',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 期数接口
export function _getCounts(params) {
    console.log('期数接口')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/getCounts',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
// 小红点
export function _getmtOrderNumer(params) {
    console.log('小红点')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/mtOrderNumer',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}


//导出文件
export function _checkexportMtorder(params) {
  console.log('导出文件')
  return request({
      url:'/ananops/api/v1/pri/supervision/exportmtorder',
      method: 'POST',
      data: params,
      showLoading: 'true',
      responseTypes : 'true',
      tokenStatus: 'true' // 是否加token
  })
}

