// 组织架构接口
import { request, requestFd } from '../core/request'
import store from '../store'
import axios from 'axios'

// 组织架构树接口
export function _gettree(params) {
    console.log('树接口')
    return request({
        url:'/ananops/api/v1/pri/organ/scandept',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}
//新增部门
export function _setdept(params) {
    console.log('新增部门')
    return request({
        url:'/ananops/api/v1/pri/organ/dept',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

//修改部门
export function _setdeptupdate(params) {
    console.log('新增部门')
    return request({
        url:'/ananops/api/v1/pri/organ/deptupdate',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
}

// 获取用户账号数据
export function _getUserList(params) {
    console.log('获取用户账号数据')
    return request({
        url:'/ananops/api/v1/pri/organ/scanuser',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
  }
//   获取部门下的人
export function _getgetLeaders(params) {
    console.log('获取用户账号数据')
    return request({
        url:'/ananops/api/v1/pri/organ/getLeaders',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
  }
  // 新增用户账号
export function _addUserList(params) {
    console.log('新增用户账号')
    return request({
        url:'/ananops/api/v1/pri/organ/user',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
  }

//   获取角色下拉列表
export function _getcode(params) {
    console.log('公共下拉列表数据')
    return request({
        url: '/ananops/api/v1/pub/code/getcode',
        method: 'get',
        params: params,
        showLoading: 'true'
    })
}

  // 服务商登录调取的部门接口数据
  export function _getDept(params) {
    console.log('服务商登录调取的部门接口数据')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/getDept',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
  }

    // 部门删除
    export function _getdeptDel(params) {
        console.log('部门删除')
        return request({
            url:'/ananops/api/v1/pri/organ/deptDel',
            method: 'post',
            data: params,
            showLoading: 'true',
            tokenStatus: true // 是否加token
        })
      }

      
        // 用户删除
  export function _geuserDel(params) {
    console.log('用户删除')
    return request({
        url:'/ananops/api/v1/pri/organ/userDel',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
  }
//组织架构部门查询
export function _scanchilddept(params) {
console.log('组织架构部门查询')
return request({
    url:'/ananops/api/v1/pri/organ/scanchilddept',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
})
}
//组织架构用户查询
export function _scandeptuser(params) {
    console.log('组织架构用户查询')
    return request({
        url:'/ananops/api/v1/pri/organ/scandeptuser',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
    }
  


