//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _addUserList } from "@/api/organizational";
export default {
  name: "adddialog",
  data() {
    return {
      dialogVisible: false,
      active: "dialog_tem_box asas",
      newactive: "",
    };
  },
  methods: {
    // 弹层的开关
    dialogswith() {
      this.dialogVisible = true;
    },
    // 工单事件
    order() {
      this.active = "dialog_tem_box asas";
      this.newactive = "";
      this.$router.push("/main/maintenance/mode/ordertempl")
    },
    // 设备事件
    equipment() {
      this.active = "";
      this.newactive = "dialog_tem_box asas";
      this.$router.push("/main/maintenance/mode/equipment")

    },
  },
};
